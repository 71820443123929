@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --app-cursor-default: url(/images/default-b2d72215.svg) 16 12, default;
    --app-cursor-pointer: url(/images/pointer-84176aa3.svg) 13 12, pointer;
    --app-cursor-active: url(/images/active-1352ec3e.svg) 13 12, pointer;
    --app-cursor-special: url(/images/special-8dad077a.svg) 21 9, pointer;
    --radial-gradient-background: 250, 250, 250;
    --solid-color-background: 15, 15, 15;
    --overlay-color: 255, 255, 255;
  }
}

::selection {
  background: #e7e0ff;
  color: #1a0570;
}

body {
  background: #110348;
  cursor: var(--app-cursor-default);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  font-family: "Satoshi", sans-serif;
}

html {
  height: 100%;
  width: 100%;
  cursor: var(--app-cursor-default);
}

@layer utilities {
  .noise-overlay {
    z-index: 399;
    opacity: 0.3;
    mix-blend-mode: overlay;
    background-image: url(/images/noise.png);
    background-position: 0 0;
    background-size: 200px;
    display: block;
    position: absolute;
    inset: 0%;
    pointer-events: none;
  }

  .card-wrapper {
    @apply relative w-full overflow-hidden bg-[#0e0235f4];
  }

  .card-wrapper::before {
    background: conic-gradient(
      transparent 0deg,
      transparent 330deg,
      #e7e0ff 340deg,
      #7044ff
    );

    @apply animate-border-spin 4xl:h-[290%] 4xl:w-[200%] 4xl:top-[-95%] 4xl:left-[-50%] absolute left-[-60%] top-[-60%] h-[220%] w-[220%] content-[""] 2xl:left-[-70%] 2xl:top-[-70%] 2xl:h-[240%] 2xl:w-[240%];
  }

  .card-content {
    @apply absolute left-[1px] top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)];
  }

  .transparent-text {
    -webkit-text-stroke: 1px #b2acff;
    color: transparent;
  }

  .hero-heading {
    @apply font-Acorn-Bold text-primary-300 max-w-full text-center tracking-[-2px] will-change-transform lg:tracking-[-4px];
  }

  .hero-text {
    @apply text-[clamp(4rem,0.5692rem+8.5vw,13.75rem)] leading-[1];
  }

  .hero-paragraph {
    @apply text-primary-200 3xl:max-w-4xl relative max-w-2xl text-center  tracking-[-0.3px] xl:max-w-3xl;
  }

  .hero-paragraph-text {
    @apply text-[clamp(1rem,.8852rem+0.3898vw,1.375rem)] leading-[1.8];
  }

  .work-heading {
    @apply font-Acorn-Bold text-primary-300 max-w-full text-center text-[clamp(3.2rem,.5692rem+7.5vw,13.75rem)] leading-[1] tracking-[-2px];
  }

  .footer-cc-logo {
    @apply font-Acorn-Bold text-primary-100 text-4xl uppercase tracking-wider sm:text-4xl md:text-5xl lg:text-6xl;
  }

  .footer-container {
    @apply 2xl:max-w-8xl 3xl:max-w-9xl 4xl:max-w-10xl xs:max-w-lg vxs:max-w-md vs:max-w-[27rem] mx-auto;
  }

  .footer-text {
    @apply xs:text-base text-sm;
  }

  .footer-link {
    @apply active:cursor-active text-primary-300 flex cursor-pointer items-center justify-end font-light tracking-wider decoration-wavy md:hover:underline md:hover:underline-offset-4;
  }

  .qualites-transparent-heading {
    @apply work-heading !transparent-text ease-in-out-quad absolute inset-0 opacity-0 transition duration-700 group-hover:-translate-y-10 group-hover:opacity-100;
  }

  .journey-paragraph {
    @apply text-primary-200 relative px-10 text-base !leading-[2.2] tracking-wide sm:max-w-2xl sm:px-5 sm:text-lg md:max-w-3xl lg:max-w-4xl lg:px-0 2xl:text-xl 2xl:leading-[2.3];
  }

  /* Shiny Button */

  .radial-gradient {
    background: radial-gradient(circle at 50% 0%, #22087574 0%, transparent 60%)
      #100444;
  }

  .linear-mask {
    mask-image: linear-gradient(
      -75deg,
      white calc(var(--x) + 20%),
      transparent calc(var(--x) + 30%),
      white calc(var(--x) + 100%)
    );
    -webkit-mask-image: linear-gradient(
      -75deg,
      white calc(var(--x) + 20%),
      transparent calc(var(--x) + 30%),
      white calc(var(--x) + 100%)
    );
  }

  .linear-overlay {
    background-image: linear-gradient(
      -75deg,
      rgba(var(--overlay-color), 0.1) calc(var(--x) + 20%),
      rgba(var(--overlay-color), 0.5) calc(var(--x) + 25%),
      rgba(var(--overlay-color), 0.1) calc(var(--x) + 100%)
    );
    mask: linear-gradient(black, black) content-box,
      linear-gradient(black, black);
    -webkit-mask: linear-gradient(black, black) content-box,
      linear-gradient(black, black);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
  }

  .prose pre {
    @apply m-0 w-full bg-transparent p-0;
  }
}
